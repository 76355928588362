<template>
  <div class="person_bg">
    <div class="personInformation">

      <div class="person_left">
        <ul class="form_item">
          <div class="form">
            <li class="item" 
               v-for="(item,index) of menuList" :key="index" 
              :class="{active: isActive === index}"
               @click="menuChange(item.value,index)">
              <div class="thinLeft"></div>
              <span class="span">{{ item.name }}</span>
            </li>
          </div>
        </ul>
        <!-- <a-layout class="layout" id="components-layout-demo-top-side-2">
          <a-layout-sider width="240" hight="auto">
            <a-menu mode="inline" 
            :selected-keys="[current]"
            @click="handleClick"
            >
              <a-sub-menu key="sub1" >
                <span slot="title"  >个人中心</span>
                <a-menu-item key="PersonInfo">
                  个人信息
                </a-menu-item>
              </a-sub-menu>
              <a-sub-menu key="sub2">
                <span slot="title">申请入驻</span>
                <a-menu-item key="EntryMaterials">
                  入驻资料
                </a-menu-item>
              </a-sub-menu>
              <a-sub-menu key="sub3">
                <span slot="title"> 资质审核</span>
                <a-menu-item key="CheckInformation" >
                  审核信息
                </a-menu-item>
                <a-menu-item key="SchemeChoice">
                  方案选择
                </a-menu-item>
                <a-menu-item key="SignContract">
                  签订合同
                </a-menu-item>
              </a-sub-menu>
              <a-sub-menu key="sub4">
                <span slot="title"> 余额管理</span>
                <a-menu-item key="AccountManagement">
                  余额管理
                </a-menu-item>
              </a-sub-menu>
            </a-menu>
          </a-layout-sider>
        </a-layout> -->
      </div>
      <div class="person_right">
        <div :is="current"></div>
      </div>
    </div>
  </div>
</template>
<script>
import AccountManagement from "./components/accountManagement"
import CheckInformation from "./components/checkInformation"
import EntryMaterials from "./components/entryMaterials"
import PersonInfo from "./components/personInfo"
import SchemeChoice from "./components/schemeChoice"
import SignContract from "./components/signContract"
export default {
  components: {
    AccountManagement,
    CheckInformation,
    EntryMaterials,
    PersonInfo,
    SchemeChoice,
    SignContract
  },
  data () {
    return {
      show: false,
      current: 'PersonInfo',
      isActive:0,
      menuList:[
        {
          name:'个人信息',
          value:'personInfo'
        },
        {
          name:'申请入驻',
          value:'entryMaterials'
        }
      ]
    }
  },
  methods: {
    handleClick (e) {
      console.log('click ', e);
      this.current = e.key;
    },
    open () {
      this.show = !this.show
    },
    menuChange (menu,i) {
      this.current = menu
      this.isActive = i
    }
  }
}
</script>
<style lang="scss" scoped>
.person_bg {
  background-color: #f8f8f8;
  padding-bottom: 150px;

  .personInformation {
    display: flex;
    justify-content: space-around;
    width: 1200px;
    margin: 40px auto;
    padding-top: 20px;

    .person_left {
      width: 240px;

      .layout {
        border-radius: 10px;
      }

      .form_item {
        margin: 0;
        padding: 0;
        background-color: #ffffff;
        border-radius: 5px;

        .form {
          padding-top: 5px;
          padding-bottom: 5px;

          .item {
            height: 50px;
            line-height: 50px;
            list-style-type: none;
            display: flex;
            align-items: center;
            cursor: pointer;

            .thinLeft {
              border-left: 4px solid transparent;
              height: 24px;

            }

            .span {
              margin-left: 40px;
            }
          }

          .item:hover {
            color: rgba(0, 0, 0, 1);
            background-color: #f5f5f5;

            .thinLeft {
              line-height: 24px;
              // border-left: 4px solid #ffd000;
            }
          }
          .active  {
            color: rgba(0, 0, 0, 1);
         
            .thinLeft {
              line-height: 24px;
              border-left: 4px solid #ffd000;
            }
          }
        }

      }
    }

    .person_right {
      width: 930px;
      margin-left: 40px;
      background-color: #fff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>